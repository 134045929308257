import { computed } from "vue";
import {required,requiredIf,minLength,maxLength,integer,email,helpers,
} from "@vuelidate/validators";
import { props } from "@jsRoot/upload/props";
const alphaSpace = (value) =>!helpers.req(value) || helpers.regex(/^[a-zA-Z\s]*$/);
const boolCheck = (prop) =>
    helpers.withParams(
        { type: "boolCheck", value: prop },
        (agree) =>!helpers.req(agree) ||(prop.Enabled && prop.Required) ||(typeof agree === "boolean" && agree === true)
    );

const rules = computed(() => ({
    authorizeContact: {
        required: requiredIf(
            () =>!!props.authorizeContact &&!!props.authorizeContact.Enabled &&!!props.authorizeContact.Required
        ),
        shouldBeTrue: boolCheck(props.authorizeContact),
    },
    prescriptions: {
        required: requiredIf(
            () =>!!props.uploadPrescription &&!!props.uploadPrescription.Enabled &&!!props.uploadPrescription.Required
        ),
    },
    email: {email,maxLength: maxLength(48),
    },
    query: {
        required: requiredIf(
            () =>
                !!props.query && !!props.query.Enabled && !!props.query.Required
        ),alpha: alphaSpace,maxLength: maxLength(256),
    },
    service: {
        required: requiredIf(
            () =>!!props.service &&!!props.service.Enabled &&!!props.service.Required
        ),
    },
    name: {required,alpha: alphaSpace,maxLength: maxLength(40),
    },
    phone: {required,number: integer,minLength: minLength(10),maxLength: maxLength(10),
    },
    consentWhatsapp: {
        required: requiredIf(
            () =>!!props.consentWhatsapp &&!!props.consentWhatsapp.Enabled &&!!props.consentWhatsapp.Required
        ),
        shouldBeTrue: boolCheck(props.consentWhatsapp),
    },
}));

export { rules };
