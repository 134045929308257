import { reactive, computed } from "vue";
import { fetchServicesApi } from "@settings";

export default function useServices() {
    const state = reactive({
        services: [],
    });
    const services = computed(() => state.services);
    const fetchServices = async () => {
        try {
            const config = {
                url: fetchServicesApi,
                method: "POST",
                data: {},
            };
            delete axios.defaults.headers.common["X-CSRF-TOKEN"];
            delete axios.defaults.headers.common["X-Requested-With"];
            axios.defaults.withCredentials = false;
            axios(config)
                .then(function (response) {
                    if (
                        response.hasOwnProperty("data") &&
                        response.data.hasOwnProperty("data") &&
                        response.data.hasOwnProperty("status") &&
                        response.data.status == "success"
                    ) {
                        state.services = response.data.data;
                    }
                })
                .catch(function (error) {
                    console.error(error);
                });
        } catch (e) {
            console.log("Error Loading Services");
        }
    };
    return { services: services, fetchServices };
}
