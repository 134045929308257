import { vd } from "@jsRoot/upload/validator";
import { state } from "@jsRoot/upload/state";
const validatePrescription = (e) => {
    vd.value.prescriptions.$touch();
    e.stopPropagation();
    let files = e.target.files || e.dataTransfer.files;
    if (files.length) {
        let f = e.target.files[0];
        if (f.size >= 2 * 1024 * 1024) {
            Toast.fire({
                icon: "warning",
                title: "Only upload file of maximum 2MB!",
            });
            e.target.value = null;
            state.prescriptions = {};
            e.preventDefault();
            return false;
        }
        if (!f.type.match(
                "(image/jp.*|application/pdf|application/doc.*|image/pn.*|application/vnd.openxmlformats-officedocument.wordprocessingml.document)"
            )) {
            if (window.hasOwnProperty("Toast")) {
                Toast.fire({
                    icon: "warning",
                    title: "We Support PDF, DOC, JPG & PNG!",
                });
            }
            e.target.value = null;
            state.prescriptions = {};
            e.preventDefault();
            return false;
        }

        let fl = new FileReader();
        fl.onload = (e) => {
            let bs = new Uint8Array(e.target.result);
            let i =
                bs.length > 4 &&
                bs[0] == 0xff &&
                bs[1] == 0xd8 &&
                bs[2] == 0xff &&
                bs[3] == 0xe0;
            let x =
                bs.length > 4 &&
                bs[0] == 0x50 &&
                bs[1] == 0x4b &&
                bs[2] == 0x03 &&
                bs[3] == 0x04;
            let dx =
                bs.length > 4 &&
                bs[0] == 0x50 &&
                bs[1] == 0x4b &&
                bs[2] == 0x05 &&
                bs[3] == 0x06;
            let dcx =
                bs.length > 4 &&
                bs[0] == 0x50 &&
                bs[1] == 0x4b &&
                bs[2] == 0x07 &&
                bs[3] == 0x08;
            let p =
                bs.length > 5 &&
                bs[0] == 0x25 &&
                bs[1] == 0x50 &&
                bs[2] == 0x44 &&
                bs[3] == 0x46 &&
                bs[4] == 0x2d;
            let d =
                bs.length > 8 &&
                bs[0] == 0xd0 &&
                bs[1] == 0xcf &&
                bs[2] == 0x11 &&
                bs[3] == 0xe0 &&
                bs[4] == 0xa1 &&
                bs[5] == 0xb1 &&
                bs[6] == 0x1a &&
                bs[7] == 0xe1;
            let g =
                bs.length > 8 &&
                bs[0] == 0x89 &&
                bs[1] == 0x50 &&
                bs[2] == 0x4e &&
                bs[3] == 0x47 &&
                bs[4] == 0x0d &&
                bs[5] == 0x0a &&
                bs[6] == 0x1a &&
                bs[7] == 0x0a;
            if (i || p || d || g || x || dx || dcx) {
                state.prescriptions = files;
            } else {
                if (window.hasOwnProperty("Toast")) {
                    Toast.fire({
                        icon: "warning",
                        title: "We Support PDF, DOC, JPG & PNG!",
                    });
                }
                e.target.value = null;
                state.prescriptions = {};
                e.preventDefault();
                return false;
            }
        };
        fl.readAsArrayBuffer(f);
    }
};

export { validatePrescription };