import { objectValidator } from "vue-props-validation";
const propsValidator = {
    consentWhatsapp: {
        type: Object,
        validator: objectValidator({Enabled: { type: Boolean, required: true },Required: { type: Boolean, required: true },Placeholder: { type: String, required: true },
        }),
    },
    uploadPrescription: {
        type: Object,
        validator: objectValidator({Enabled: { type: Boolean, required: true },Required: { type: Boolean, required: true },Placeholder: { type: String, required: true },
        }),
    },
    service: {
        type: Object,
        validator: objectValidator({Enabled: { type: Boolean, required: true },Required: { type: Boolean, required: true },Placeholder: { type: String, required: true },
        }),
    },
    authorizeContact: {
        type: Object,
        validator: objectValidator({Enabled: { type: Boolean, required: true },Required: { type: Boolean, required: true },Placeholder: { type: String, required: true },
        }),
    },
    query: {
        type: Object,
        validator: objectValidator({Enabled: { type: Boolean, required: true },Required: { type: Boolean, required: true },Placeholder: { type: String, required: true },
        }),
    },
};

export { propsValidator };
