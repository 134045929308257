import { computed } from "vue";
import { state } from "@jsRoot/upload/state";
import { vd } from "@jsRoot/upload/validator";
import { props } from "@jsRoot/upload/props";
const uploadFile = computed(() => state.prescriptions && state.prescriptions[0] ? true : false);
const uploadFileName = computed(() => state.prescriptions && state.prescriptions[0]? state.prescriptions[0].name: "");
const isFileDirty = computed(() => vd.value.prescriptions.$dirty);
const shouldValidateFile = computed(() => !!props.uploadPrescription && !!props.uploadPrescription.Enabled);
const isValidFile = computed(() =>  state.prescriptions &&Boolean(Object.keys(state.prescriptions).length &&vd.value.prescriptions.$dirty)? true: false);
const shouldSubmit = computed(() => !state.submitting &&!vd.value.$invalid &&(shouldValidateFile.value ? isValidFile.value : true)? true: false);
export {
    uploadFile,
    uploadFileName,
    isFileDirty,
    isValidFile,
    shouldValidateFile,
    shouldSubmit,
};
