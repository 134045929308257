<template>
  <LabTestForm
    :upload-prescription="props['uploadPrescription']"
    :query="props['query']"
    :service="props['service']"
    :consent-whatsapp="props['consentWhatsapp']"
    :authorize-contact="props['authorizeContact']"
  ></LabTestForm>
</template>

<script>
export { default } from "./Controller";
</script>
