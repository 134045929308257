import { asset } from "@helpers/asset";
import { defineComponent, computed, h } from "vue";
import {NSelect,NSpace,NAvatar,NInput,NIcon,NButton,NInputGroup,NSwitch,NCheckbox,NP,
} from "naive-ui";
import {PersonCircleOutline as PersonIcon,CallOutline as PhoneIcon,MailOutline as MailIcon,
} from "@vicons/ionicons5";
import {feedbackSubmitApi,thankYouPage,selectThemeOverrides,inputThemeOverrides,buttonThemeOverrides,typographyThemeOverrides,railStyle,
} from "@jsRoot/upload/settings";
import { validatePrescription } from "@jsRoot/upload/fileValidator";
import { state } from "@jsRoot/upload/state";
import { vd } from "@jsRoot/upload/validator";
import { propsValidator } from "@jsRoot/upload/propsValidator";
import {uploadFile,uploadFileName,isFileDirty,isValidFile,shouldValidateFile,shouldSubmit,
} from "@jsRoot/upload/watchers";
import useServices from "@jsRoot/upload/fetchers/useServices";
export default defineComponent({
    name: "LabTestForm",
    components: {NSelect,NSpace,NInput,NSwitch,NCheckbox,NP,NIcon,NAvatar,NButton,NInputGroup,PersonIcon,PhoneIcon,MailIcon,
    },
    props: propsValidator,
    setup(props) {
        const { services, fetchServices } = useServices();
        fetchServices();
        const servicesMapped = computed(() =>
            services.value
                ? services.value.map((st) => ({
                      label: st.ServiceName,
                      value: st.id,
                      icon: asset(`img/service-icon.png`),
                  }))
                : []
        );
        const renderLabel = (option) => {
            return h(
                "div",
                {
                    style: {
                        display: "flex",
                        alignItems: "center",
                    },
                },
                [
                    h(NAvatar, {
                        src: option.icon,
                        round: true,
                        size: "small",
                        style: {
                            background: "transparent",
                        },
                    }),
                    h(
                        "div",
                        {
                            style: {
                                marginLeft: "12px",
                                padding: "8px 0",
                            },
                        },
                        [h("div", null, [option.label])]
                    ),
                ]
            );
        };
        const submitFeedback = () => {
            vd.value.$touch();
            if (shouldSubmit.value) {
                const fd = new FormData();
                Object.entries(state).forEach(([name, value]) => {
                    if (!!value) {
                        if (typeof value === "object") {
                            fd.append(name, value[0]);
                        } else {
                            fd.append(name, value);
                        }
                    }
                });
                state.submitting = true;
                axios
                    .post(feedbackSubmitApi, fd)
                    .then((res) => {
                        console.info("DEBUG LOG #res", res);

                        if (res.data.status === "success") {
                            window.location.href = thankYouPage;
                        }
                    })
                    .catch((err) => {
                        console.info("DEBUG LOG #err", err);
                        state.submitting = false;
                    });
            }
        };

        return {vd,props,uploadFileName,services,state,uploadFile,selectThemeOverrides,servicesMapped,isValidFile,shouldValidateFile,isFileDirty,validatePrescription,shouldSubmit,renderLabel,buttonThemeOverrides,typographyThemeOverrides,asset,inputThemeOverrides,railStyle,submitFeedback,
        };
    },
});
