import { defineComponent } from "vue";
import LabTestForm from "./components/LabTestForm/View.vue";
import { props } from "@jsRoot/upload/props";

export default defineComponent({
    name: "LabTestContact",
    components: {
        LabTestForm,
    },
    setup() {
        return { props };
    },
});