import "es6-promise/auto";
import * as Vue from "vue";
import App from "./components/LabTestContact/View.vue";
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
window.axios.defaults.headers.common["X-CSRF-TOKEN"] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");
window.axios.defaults.withCredentials = true;
Vue.createApp(App).mount("#lab-test-app");
